.portfolio-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
    margin: 20px 0;
}

@media only screen and (max-width: 600px){
    .portfolio-list{
        gap: 30px;
    }
}