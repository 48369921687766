.navigation-main{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    gap: 50px;
    border-bottom: 20px solid var(--secondary-color);
    background-color: var(--primary-color);
}

.navigation-identity{
    display: flex;
    align-items: center;
    margin-right: 50px;
}

.navigation-menu{
    display: flex;
    align-items: center;
    gap: 50px;
    margin-left: 50px; 
}

/*Mobile*/
.navigation-hamburger-menu-btn{
    display: none;
    font-size: 3rem; 
    color: var(--secondary-color);
}

.navigation-hamburger-menu{
    flex-direction: column;
    background-color: var(--primary-color);
    margin-left: 0; 
    width: 100%;
    padding: 30px 0;
}

@media only screen and (max-width: 1300px){
    .navigation-menu{
        gap: 20px;
        margin-left: 0; 
    }
    .navigation-hamburger-menu-btn{
        display: block;
    }
}

@media only screen and (max-width: 750px){
    .navigation-main{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding: 20px;
    }

    .navigation-identity{
        margin-right: 0;
    }
}

@media only screen and (max-width: 450px){
    .navigation-identity{
       flex-direction: column;
       justify-content: center;
       align-items: center;
    }
}