.gamecard {
    width: 250px;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.game-icon{
    width: auto;
    border: 3px solid var(--secondary-color);
    border-radius: 10px;
}

.tech-stack{
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
}

.tech-icon{
    max-width: 40px;
    background-color: var(--secondary-color);
    border-radius: 5px;
}

.card-title h2{
    font-size: 1.5rem;
}